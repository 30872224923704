import { default as cartltOYTvmzvgMeta } from "/opt/buildhome/repo/pages/cart.vue?macro=true";
import { default as customerDGTc9fo7eyMeta } from "/opt/buildhome/repo/pages/customer.vue?macro=true";
import { default as error5bJL3YgCAEMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as paymentt9BiBTWFpLMeta } from "/opt/buildhome/repo/pages/payment.vue?macro=true";
import { default as shippingFRNo84PUVxMeta } from "/opt/buildhome/repo/pages/shipping.vue?macro=true";
import { default as successBVIqjShRzzMeta } from "/opt/buildhome/repo/pages/success.vue?macro=true";
import { default as cartG56hCyeg5uMeta } from "~/pages/cart.vue?macro=true";
export default [
  {
    name: "cart",
    path: "/cart",
    meta: cartltOYTvmzvgMeta || {},
    component: () => import("/opt/buildhome/repo/pages/cart.vue")
  },
  {
    name: "customer",
    path: "/customer",
    component: () => import("/opt/buildhome/repo/pages/customer.vue")
  },
  {
    name: "error",
    path: "/error",
    meta: error5bJL3YgCAEMeta || {},
    component: () => import("/opt/buildhome/repo/pages/error.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/opt/buildhome/repo/pages/payment.vue")
  },
  {
    name: "shipping",
    path: "/shipping",
    component: () => import("/opt/buildhome/repo/pages/shipping.vue")
  },
  {
    name: "success",
    path: "/success",
    meta: successBVIqjShRzzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/success.vue")
  },
  {
    name: "init",
    path: "/",
    meta: cartG56hCyeg5uMeta || {},
    component: () => import("~/pages/cart.vue")
  }
]